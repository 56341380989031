import request from '../../utils/request'
// const token=localStorage.getItem("access_token");


//查询房间模板信息
export function templateInfo(templateId,token) {
    return request({
        url:"/hotel/template/getTemplateInfo",
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
        }
    })
}

//获取设备列表
export function deviceList(templateId,token) {
    return request({
        url:"/hotel/template/getTemplateDeviceList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
        }
    })
}
//获取场景列表
export function sceneList(templateId,token) {
    return request({
        url:"/hotel/template/getListTemplateScene",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
        }
    })
}

//获取红外设备品牌列表
export function getInfraredCode(productType,token) {
    return request({
        url:"/hotel/template/getInfraredDeviceCode",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            productType:productType
        }
    })
}
//批量添加设备
export function saveDevice(templateDevices,templateId,voiceType,token) {
    return request({
        url:"/hotel/template/saveBatchTemplateDevice?templateId="+templateId+"&voiceType="+voiceType,
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        data:templateDevices
    })
}
//删除单个设备
export function removeDevice(templateId,templateDeviceId,token) {
    return request({
        url:"/hotel/template/removeTemplateDevice",
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
            templateDeviceId:templateDeviceId,
        }
    })
}
//更新单个设备
export function updateTempDevice(templateId,voiceType,templateDevice,token) {
    return request({
        url:"/hotel/template/updateTemplateDevice?templateId="+templateId+"&voiceType="+voiceType,
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        data:templateDevice
    })
}

//修改模板
export function updateTemplate(templateId,templateName,remarks,welcomeMsg,token) {
    return request({
        url:"/hotel/template/updateTemplate",
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
            templateName:templateName,
            remarks:remarks,
            welcomeMsg:welcomeMsg
        }
    })
}

//新增修改场景
export function addScene(templateId,data,token) {
    return request({
        url:"/hotel/template/saveOrUpdateTemplateScene?templateId="+templateId,
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token,
        },
        data:data
    })
}
//删除单个场景
export function removeScene(templateId,templateSceneId,token) {
    return request({
        url:"/hotel/template/removeTemplateScene",
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
            templateSceneId:templateSceneId,
        }
    })
}
//查询单个场景信息
export function getSceneDetail(templateSceneId,token) {
    return request({
        url:"/hotel/template/getOneTemplateScene",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateSceneId:templateSceneId,
        }
    })
}

//获取场景图标列表
export function senceIconInfo(token) {
    return request({
        url:"/hotel/template/getSceneIconList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
